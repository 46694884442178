
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import { Dictionary } from "vue-router/types/router";

import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "users",

  mixins: [tablePagination, tableFilters],

  data: () => ({
    rules,
    parseArrayParams,
    headers: [] as Array<HeadersInterface>,
    items: [] as Array<any>,
    filter: {} as Dictionary<string | (string | null)[]>,
    showSearch: false as boolean,
    loading: false as boolean,
    queryString: "" as string,
    selects: {
      roles: [] as Array<SelectComponentInterface>
    },
    password: {
      old_password: "" as string,
      password: "" as string,
      confirm_confirmation: "" as string
    } as any,
    shownPassword: {
      old_password: false as boolean,
      password: false as boolean,
      confirm_confirmation: false as boolean,
      password_confirmation: false as boolean
    } as any,
    errorMessages: {} as any,
    selectedUser: {} as any,
    changePasswordDialog: false as boolean,
    showMenu: false as boolean,
    x: 0,
    y: 0,
    activeItemId: null as any
  }),

  computed: {
    computedRules(): object {
      return {
        minNumber: this.min,
        samePassword: this.samePassword
      };
    },
    isPassword() {
      return this.password.old_password && this.password.password;
    },
    isSamePassword() {
      return this.password.old_password === this.password.password;
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };
      const response = await API.users().getData(params);
      const roles = await API.roles().getList();
      const subdivizions = await API.subdivisions().getList();

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
        vm.selects.roles = roles;
        vm.selects.subdivisions = subdivizions.items;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    getPasswordType(key: string): string {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    },
    min(number: number): Function {
      return (v: string): boolean | string =>
        !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    samePassword(): Function {
      return (v: string): boolean | string =>
        v === this.password.password || `Пароль не совпадает!`;
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.users().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });

        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setServerResponse(response: any) {
      this.headers = [
        ...response.headers,
        {
          text: "",
          value: "actions",
          width: "14em",
          align: "center",
          sortable: false
        }
      ];
      this.items = response.items.data;
      this.totalItems = response.items.total;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
        this.showSearch = true;
      }

      if (filter.hasOwnProperty("roles")) {
        newFilters.roles = newFilters.roles.split(",").map(Number);
      }

      if (filter.hasOwnProperty("subdivisions")) {
        newFilters.subdivisions = newFilters.subdivisions
          .split(",")
          .map(Number);
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    },
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    async deleteRow(id: number): Promise<any> {
      try {
        await this.$API.users().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changePassword(): Promise<void> {
      try {
        if ((this.$refs.passwordForm as any).validate()) {
          await this.$API
            .users()
            .changePassword(this.selectedUser.id, this.password.password);

          this.password = {};

          this.changePasswordDialog = false;
          this.selectedUser = {};
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("users.alert.change_password_message")
          );
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
        if (e.errors) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
      }
    },
    toggleChangePasswordDialog(item: any): void {
      this.selectedUser = { ...item };
      this.changePasswordDialog = true;
    },
    closeChangePasswordDialog(): void {
      this.selectedUser = {};
      this.password = {};
      this.changePasswordDialog = false;
    },
    showPassword(key: string): void {
      this.shownPassword[key] = true;
    },
    hidePassword(key: string): void {
      this.shownPassword[key] = false;
    },
    togglePasswordType(key: string): void {
      this.shownPassword[key] = !this.shownPassword[key];
    },
    async loginAs(item: any) {
      try {
        const response = await this.$API.users().loginAs(item.id);
        const [role] = (response as any).user.roles;

        await this.$store.dispatch("user/setRole", role);
        await this.$store.dispatch("authentication/login", response);
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch(e.message);
      }
    },
    showContextMenu(e: any, item: any) {
      this.activeItemId = item.item.id;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }
});
